<template>
	<div class="home">

		<div class="overlap-wrapper">
			<div class="overlap">
				<img class="mask-group" alt="Mask group" src="@/assets/mask-group.png" />
				<div class="group">
					<img class="img" alt="Group" src="@/assets/group-1.png" />
					<p class="text-wrapper">Gallery Board for Whatsapp - GBWhatsapp</p>
					<p class="div">Personalize your WhatsApp chats with stunning wallpapers.</p>
					<div class="overlap-group">
						<div class="start-free-trial">Download</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import "@/css/main.scss";
import "@/css/pc.scss";

export default {
	name: "pc",
	data() {
		return {
			is_mobile: null,
		};
	},
	mounted() {
		this.$logEvent("show_gbhome");
	},
	methods: {
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			return flag;
		},
	},
};
</script>
